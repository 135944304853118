<template>
  <div class="file-uploader" data-test="file-upload">
    <div class="file-uploader-title" v-if="title.length > 0">{{ title }}</div>
    <div class="file-uploader-description" v-if="description.length > 0">{{ description }}</div>

    <div class="grid">
      <div v-for="(item, index) in value" :key="index">
        <form-group :label="getLabel(index)" :value="hasValue(index)" :errors="hasErrors(index)">
          <button class="btn-file-close" v-if="value.length > minSize" @click.prevent="removeInput(index)" data-test="file-upload_remove">
            <span>Close</span>
            <icon-close color="#EA4C17" size="12"/>
          </button>

          <div style="position: relative">
            <v-select
                v-model="value[index].type"
                :reduce="option => option.id"
                class="form-control"
                @option:selected="$emit('change')"
                :clearable="false"
                :options="types"
                v-bind="customAttributes"
            />
            <label class="file-upload-icon">
              <icon-attach size="22" color="#19191C" @click.native.stop/>
              <input type="file" :accept="acceptExtensionsList" :ref="name" @change.prevent="fileUpload(index, $event)">
            </label>
          </div>

          <p class="file-uploaded-text" v-show="loading[index]">
          <span class="progress">
            <span class="progress-line" :style="{width: progress[index] + '%'}"></span>
          </span>
          </p>

          <p class="file-uploaded-text" v-show="!loading[index] && value[index].original_name">
            <a :href="value[index].url" target="_blank">
              <span>{{ value[index].original_name }}</span>
              <span @click.prevent="removeFile(index)" class="file-uploaded-delete">
                <icon-close color="#19191C" size="12" data-test="file-upload_remove_file"/>
              </span>
            </a>

          </p>
        </form-group>
      </div>

      <button class="btn-add-more" @click.prevent="addInput" data-test="file-upload_add">
        <icon-add color="#19191C" size="21" />
        <span>Add more</span>
      </button>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import IconAdd from "@/assets/icons/IconAdd";
import IconClose from "@/assets/icons/IconClose";
import IconAttach from "@/assets/icons/IconAttach";
import { mapState } from 'vuex'

export default {
  name: 'FileUpload',
  components: {
    IconAttach,
    IconClose,
    FormGroup,
    IconAdd
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    minSize: {
      type: Number,
      default: 1
    },
    types: {
      type: Array,
      default: () => []
    },
    errors: {
      type: [String, Array, Object]
    },
    label: {
      type: String,
      default: 'Files'
    },
    labelDescription: {
      type: String,
    },
    name: {
      type: String,
      default: 'files'
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    customInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: [],
      progress: []
    }
  },
  watch: {
    /**
     * Update internal loading state
     * @param value
     */
    loading: function (value) {
      let i = value.find(i => i === true)
      this.$emit('loading', i)
    },
    value: function (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    ...mapState({
      accept: state => state.init.fileConditions
    }),
    acceptExtensionsList () {
      if (this.accept && this.accept.extensions) {
        return this.accept.extensions.join(', ')
      }

      return ''
    },
    extractErrors () {
      if (!this.errors) {
        return []
      }

      let keys = Object.keys(this.errors).filter(item => {
        return item.substr(0, this.name.length) === this.name
      })

      let errors = []

      for (let key of keys) {
        errors.push({
          key: key,
          value: this.errors[key]
        })
      }

      return errors
    },
    customAttributes () {

      if (this.customInput) {
        return {
          'taggable': true,
          'create-option': (name) => ({id: name, label: name}),
          'select-on-key-codes' : [9, 13]
        }
      }

      return {}
    }
  },
  created() {
    this.$nextTick(() => {
      if (Array.isArray(this.value) && (this.value.length === 0)) {
        for(let i = 0; i < this.minSize; i++) {
          this.addInput()
        }
      }
    })
  },
  methods: {
    hasValue (index) {
      return this.value[index].name !== undefined
          && this.value[index].type !== undefined
          ? ' ' : ''
    },
    hasErrors (index) {
      if (this.value[index].error) {
        return this.value[index].error
      }

      if (this.extractErrors) {
        for (let error of this.extractErrors) {
          let key = `${this.name}[${index}]`

          if (error.key.substr(0, key.length) === key) {
            return error.value
          }
        }
      }

      return ''
    },
    getLabel (index) {
      return index > 0 ? ' ' : this.label
    },
    addInput () {
      if (Array.isArray(this.value)) {
        this.value.push({
          original_name: undefined,
          name: undefined,
          type: undefined,
          url: undefined
        })
      }
    },
    removeInput (index) {
      this.value.splice(index, 1)
      this.$emit('change')
    },
    removeFile (index) {
      this.setFile(index)
      this.$emit('change')
    },
    fileUpload (index, event) {
      this.$set(this.loading, index, true)
      this.$set(this.progress, index, 0)

      const file = event.target.files && event.target.files[0]
          ? event.target.files[0]
          : false

      if (file) {
        const data = new FormData()
        data.append('file', event.target.files[0])

        let config = {
          onUploadProgress: (progressEvent) => {
            let val = Math.round( (progressEvent.loaded * 100) / progressEvent.total)
            this.$set(this.progress, index, val)

            if (val === 100) {
              this.$delete(this.progress, index)
            }
          }
        }

        this.$sdk.registration.files.upload(data, config).then(response => {
          this.setFile(index, response.data)
          this.$emit('change')
        }).finally(() => {
          this.$set(this.loading, index, false)
        })
      }

      this.$refs[this.name][index].value = ''
    },

    setFile (index, data) {
      if (data) {
        this.$set(this.value[index], 'original_name', data.original_name)
        this.$set(this.value[index], 'name', data.name)
        this.$set(this.value[index], 'url', data.url)
        this.$set(this.value[index], 'error', data.error)
      } else {
        this.$delete(this.value[index], 'original_name')
        this.$delete(this.value[index], 'name')
        this.$delete(this.value[index], 'url')

        if ('error' in this.value[index]) {
          this.$delete(this.value[index], 'error')
        }
      }
    }
  }
}
</script>


<style lang="less">

.grid > * {
  min-width: 0px;
}

.file-uploader {
  &-title {
    display: block;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    white-space: initial;
    min-height: 17px;
    line-height: 1.45em;
  }
  &-description {
    font-size: 13px;
    color: #666;
    margin-bottom: 25px;
    line-height: 1.4em;
  }

  .btn-add-more {
    font-size: 14px;
    margin: 33px 0 0;
    align-items: start;
    text-align: left;
    align-self: flex-start;

    .icon-add {
      margin-right: 10px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      position: relative;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .btn-file-close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-content: flex-end;
    align-items: center;
    color: #EA4C17;
    margin-bottom: 10px;
    margin-right: 10px;


    span {
      margin-right: 5px;
    }

    & > div {
      position: relative;
      top: 1px;
    }
  }

  .form-group {
    position: relative;
  }

  .file-upload-icon {
    display: block;
    overflow: hidden;
    width: 22px;
    height: 22px;

    position: absolute;
    right: 40px;
    top: 7px;
    z-index: 4;

    cursor: pointer;

    input {
      position: absolute;
      left: -99999px;
    }
  }

  .file-uploaded-text {
    position: relative;
    padding: 10px 37px 0 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;

    .file-uploaded-delete {
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 9px;
    }

    div {
      margin-left: 5px;
      cursor: pointer;
    }

    a {
      color: #19191C
    }

    .progress {
      border: 1px solid #FDE46A;
      width: 100%;
      height: 14px;
      display: block;
      margin-right: 20px;
      max-width: 120px;
      position: relative;

      &-line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: rgba(253, 228, 106, 0.5);
        transition: all .2s linear;
      }
    }
  }

  & > *:nth-child(odd) {
    margin-top: 0;
  }
}
</style>
