<template>
  <svg class="icon-add" :width="customSize" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"/>
    <polygon points="17 10 15 10 15 15 10 15 10 17 15 17 15 22 17 22 17 17 22 17 22 15 17 15 17 10"/>
  </svg>
</template>

<script>
export default {
  name: 'IconAdd',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  },
  computed: {
    customSize () {
      return parseInt(this.size) + 1
    }
  }
}
</script>

<style lang="less" scoped>
.icon-add {
  display: inline-block;
}
</style>
